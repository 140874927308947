import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'search'
})
export class SearchPipe implements PipeTransform {


    transform(items: any[], search: string, key: string = 'nombre'): any[] {

        if (items) {

            if (search) {

                items = items.filter(function(item) {
                    return item[key].toLowerCase().includes(search.toLowerCase());
                });
            }

            return items;
        }
    }
}
