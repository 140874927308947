import {Component, NgZone, OnInit} from '@angular/core';
import {LoadingController, ModalController, NavController, NavParams, Platform} from '@ionic/angular';

import {ConectorApi, ReservaForm} from '../../../Classes/conector-api';
import {Globales} from '../../../Classes/globales';
import {UserService} from '../../../Services/user-service.service';

@Component({
    selector: 'app-modal-evento-mesas',
    templateUrl: './modal-evento-mesas.page.html',
    styleUrls: ['./modal-evento-mesas.page.scss'],
})
export class ModalEventoMesasPage implements OnInit {

    filtros: any;
    evento: any;
    mesas: any;
    zonas: any;
    form: ReservaForm;

    constructor(
        private zone: NgZone,
        private navParams: NavParams,
        private navCtrl: NavController,
        private loadCtrl: LoadingController,
        private modalCtrl: ModalController,
        public platform: Platform,
        private conectorApi: ConectorApi,
        private gl: Globales,
        private userService: UserService,
    ) {

        this.getFiltros();
        this.evento = this.navParams.get('evento');
        this.mesas = this.navParams.get('mesas');
        this.zonas = this.navParams.get('zonas');
    }

    ngOnInit() {
    }


    /**
     * Selecciona una mesa
     *
     */
    async selecionarMesa(item) {

        const loading = await this.loadCtrl.create({
            duration: 1350,
            translucent: false,
        });

        loading.present();

        setTimeout(() => {

            this.dismiss(item);

        }, 350);
    }


    /**
     *
     */
    getFiltros() {
        this.filtros = {
            id_zona: null
        };
    }


    /**
     *
     */
    setFiltroZona(id) {


        this.zone.run(() => {

            if (id === this.filtros.id_zona) {

                this.filtros.id_zona = null;

            } else {

                this.filtros.id_zona = id;
            }
        });
    }


    /**
     * Cierra el modal y devuelve a la vista el resultado
     *
     */
    async dismiss(result = null) {

        await this.modalCtrl.dismiss(result);
    }
}
