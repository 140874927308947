import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocalesService {

    tabla = 'smd_locales';
    data;

    constructor() {

        this.data = JSON.parse(localStorage.getItem(this.tabla)) || [];

    }


    get() {

        return this.data;

    }


    set(data) {

        this.data = data;
        localStorage.setItem(this.tabla, JSON.stringify(data));

        return this.data;

    }


    delete() {

        this.data = null;
        localStorage.setItem(this.tabla, JSON.stringify([]));

        return this.data;
    }


    destroy() {

        localStorage.clear();

    }
}
