import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {FileTransfer, FileUploadOptions, FileTransferObject} from '@ionic-native/file-transfer/ngx';
import {File} from '@ionic-native/file/ngx';

@Injectable()

export class ConectorApi {

    // api: any = 'http://smd.test/api/';
    api: any = 'https://api.dev.vip.listagruposalamandra.com/api/';
    app: any = 1 + '/';
    errorBaseText: any = 'Se ha perdido la conexion con el servidor.';
    errorBaseTime = 2000;
    fileTransfer: FileTransferObject = this.transfer.create();

    constructor(
        private http: HttpClient,
        private transfer: FileTransfer,
        private file: File
    ) {
    }

    /**
     * Realiza la accion de Login
     *
     */
    async login(form: LoginForm) {

        return new Promise((resolve, reject) => {

            this.http.post(this.api + 'login', form)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Realiza la accion de registro
     *
     */
    async register(form: LoginForm) {

        return new Promise((resolve, reject) => {

            this.http.post(this.api + 'register', form)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }

    /**
     * Devuelve los locales de un usuario
     *
     */
    async getLocales(user, filters = null) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'getLocales', filters)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Devuelve un local por su ID
     *
     */
    async getLocal(user, idLocal) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'getLocal', {id: idLocal})
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Devuelve los eventos de las salas que el usuario pertenece
     *
     */
    async getEventos(user, filtros): Promise<ApiResponse> {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'getEventos', filtros)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Devuelve el eventos por su ID
     *
     */
    async getEvento(user, idEvento): Promise<ApiResponse> {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'getEvento', {id: idEvento})
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }

    /**
     * Devuelve los reservados de un evento
     *
     */
    async getReservadosEvento(user, idEvento): Promise<ApiResponse> {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'getReservadosEvento', {id: idEvento})
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Devuelve las notificaciones
     *
     */
    async getNotificaciones(user, filtros): Promise<ApiResponse> {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'getNotificaciones', filtros)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Edita una notificacion
     *
     */
    async setNotificacion(user, notificacion: NotificationForm): Promise<ApiResponse> {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'setNotificacion', notificacion)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Elimina una notificacion
     *
     */
    async deleteNotificacion(user, notificacion: NotificationForm): Promise<ApiResponse> {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'deleteNotificacion', notificacion)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Devuelve el listado de ranking de usuarios
     *
     */
    async getRanking(user, filtros) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'getRankingPro', filtros)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Devuelve el listado de ranking de usuarios
     *
     */
    async getHistoricos(user): Promise<ApiResponse> {

        return new Promise((resolve, reject) => {

            this.http.get(user.api + this.app + 'getHistoricos')
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Devuelve las solicitudes de los reservados de un usuario
     *
     */
    async getSolicitudesReservados(user, filtros): Promise<ApiResponse> {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'getSolicitudesReservados', filtros)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Envia el formulario de solicitud
     *
     */
    async setSolicitudReservado(user, form: ReservaForm) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'setSolicitudReservado', form)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }

    /**
     * Edita la solicitud
     *
     */
    async editSolicitudReservado(user, form: ReservaForm) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'editSolicitudReservado', form)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Envia un mensaje a una solicitud de reserva
     *
     */
    async setMensajeSolicitudReservado(user, form: MensajeSolicitudForm) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'setMensajeSolicitud', form)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }

    /**
     * Recupera todos los mensajes de una solicitud
     *
     */
    async getMensajesSolicitud(user, form: MensajeSolicitudForm) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'getMensajesSolicitud', form)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Elimina la imagen de perfil
     *
     */
    async deleteImegenPerfil(user) {

        return new Promise((resolve, reject) => {

            this.http.get(user.api + this.app + 'deleteImegenPerfil')
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }

    /**
     * Elimina la imagen de perfil
     *
     */
    async deleteImegenReserva(user, form) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'deleteImegenReserva', form)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Guarda la imagen de perfil
     *
     */
    async setImagenPerfil(user, path) {

        return new Promise((resolve, reject) => {

            const options: FileUploadOptions = {
                fileKey: 'file'
            };
            this.fileTransfer.upload(path, user.api + this.app + 'setImagenPerfil', options)
                .then((data) => {

                    const respuesta: ApiResponse = JSON.parse(data.response);
                    console.log(respuesta);
                    resolve(respuesta);

                }, (err) => {

                    console.log(err);
                    const error: ApiResponse = {
                        status: 0,
                        mensaje: this.errorBaseText,
                        duracion: this.errorBaseTime,
                        data: err
                    };
                    reject(error);
                });

        });
    }


    /**
     * Deveuelve los langs
     *
     */
    async getLangs(user) {

        return new Promise((resolve, reject) => {

            this.http.get(user.api + this.app + 'getLangs')
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * cambiar la contraseña
     *
     */
    async setNewPassword(user, form) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'setNewPassword', form)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Recupera una accion libre
     *
     */
    async getAccionLibre(user, apiAction) {

        return new Promise((resolve, reject) => {

            this.http.get(user.api + this.app + apiAction)
                .subscribe(
                    (respuesta: any) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }
}

export interface ApiResponse {
    status: number;
    mensaje: string;
    duracion: number;
    data: any;
}

export interface LoginForm {
    email: string;
    pass: string;
    app: number;
    platform: string;
    push?: string;
    lang?: string;
}

export interface RegisterForm {
    nombre: string;
    apellidos: string;
    email: string;
    pass: string;
    pass2: string;
    app: number;
    platform: string;
    push: string;
    lang: string;
}

export interface ReservaForm {
    id?: number;
    idLang?: number | string | null;
    idEvento?: number | null;
    idReservado?: number | null;
    idTipoPago?: number | null;
    file?: string;
    nombre: string;
    email: string;
    pax: number | null;
    precio: number | null;
    autoriza?: string | null;
    comentarios?: string | null;
}

export interface NotificationForm {
    id: number;
    vista?: boolean;
}

export interface MensajeSolicitudForm {
    id_solicitud?: number;
    mensaje?: string;
}
