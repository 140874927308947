import {NgModule} from '@angular/core';
import {SearchPipe} from './Core/search.pipe';
import {EventosPipe} from './Filtros/eventos.pipe';
import {MesasPipe} from './Filtros/mesas.pipe';
import {OrdenPipe} from './Filtros/orden.pipe';
import {ZonaPipe} from './Filtros/zona.pipe';

const declarationsExports = [
    SearchPipe,
    EventosPipe,
    MesasPipe,
    OrdenPipe,
    ZonaPipe,
];

@NgModule({
    imports: [],
    declarations: [...declarationsExports],
    exports: [...declarationsExports],
})
export class PipesModule {
}

export declare type Filters = {
    [key: string]: any;
};
