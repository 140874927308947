import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'zona'
})
export class ZonaPipe implements PipeTransform {

    transform(items: any[], filter: any[]) {

        if (filter['id_zona'] && filter['id_zona'] > 0) {

            items = items.filter(function(item) {
                return item.id == filter['id_zona'];
            });
        }

        return items;
    }

}
