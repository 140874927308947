import {Injectable} from '@angular/core';
import {ToastController} from '@ionic/angular';

@Injectable()

export class Globales {
    constructor(public toastController: ToastController) {
    }

    async showToast(mensaje, duracion = 2000, colorToast = 'warning') {
        const toast = await this.toastController.create({
            message: mensaje,
            duration: duracion,
            position: 'top',
            color: colorToast,
            buttons: [
                {
                    text: '',
                    icon: 'close',
                    role: 'cancel',
                    handler: () => {
                    }
                }
            ]
        });

        if (duracion > 0) {

            toast.present();
        }
    }
}
