import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {PhotoViewer} from '@ionic-native/photo-viewer/ngx';
import {SocialSharing} from '@ionic-native/social-sharing/ngx';
import {Push} from '@ionic-native/push/ngx';
import {Camera} from '@ionic-native/camera/ngx';
import {Crop} from '@ionic-native/crop/ngx';
import {FileTransfer} from '@ionic-native/file-transfer/ngx';
import {File} from '@ionic-native/file/ngx';
import {FileOpener} from '@ionic-native/file-opener/ngx';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {ModalLoginPage} from './Pages/Auth/modal-login/modal-login.page';
import {ModalRegisterPage} from './Pages/Auth/modal-register/modal-register.page';
import {ModalSolicitarReservadoPage} from './Pages/Reservados/modal-solicitar-reservado/modal-solicitar-reservado.page';
import {ModalEventoMesasPage} from './Pages/Reservados/modal-evento-mesas/modal-evento-mesas.page';
import {ModalChatSolicitudReservadoPage} from './Pages/Reservados/modal-chat-solicitud-reservado/modal-chat-solicitud-reservado.page';
import {ModalEditarSolicitudReservadoPage} from './Pages/Reservados/modal-editar-solicitud-reservado/modal-editar-solicitud-reservado.page';

import {ConectorApi} from './Classes/conector-api';
import {Globales} from './Classes/globales';

import {IonicStorageModule} from '@ionic/storage';

import {UserService} from './Services/user-service.service';
import {LocalesService} from './Services/locales.service';
import {EventosService} from './Services/eventos.service';
import {HistoricosService} from './Services/historicos.service';
import {NotificacionesService} from './Services/notificaciones.service';
import {RankingService} from './Services/ranking.service';
import {ReservasService} from './Services/reservas.service';
import {LangsService} from './Services/langs.service';

import es from '@angular/common/locales/es';
import {registerLocaleData} from '@angular/common';
import {ModalEventoMesasPageModule} from './Pages/Reservados/modal-evento-mesas/modal-evento-mesas.module';

registerLocaleData(es);

@NgModule({
    declarations: [
        AppComponent,
        ModalLoginPage,
        ModalRegisterPage,
        ModalSolicitarReservadoPage,
        // ModalEventoMesasPage,
        ModalChatSolicitudReservadoPage,
        ModalEditarSolicitudReservadoPage
    ],
    entryComponents: [
        ModalLoginPage,
        ModalRegisterPage,
        ModalSolicitarReservadoPage,
        // ModalEventoMesasPage,
        ModalChatSolicitudReservadoPage,
        ModalEditarSolicitudReservadoPage
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        IonicModule.forRoot({
            backButtonText: 'Atras',
        }),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        ModalEventoMesasPageModule
    ],
    providers: [
        StatusBar,
        PhotoViewer,
        SocialSharing,
        SplashScreen,
        Push,
        Camera,
        Crop,
        File,
        FileOpener,
        FileTransfer,
        ConectorApi,
        Globales,
        UserService,
        LocalesService,
        EventosService,
        HistoricosService,
        NotificacionesService,
        RankingService,
        ReservasService,
        LangsService,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
