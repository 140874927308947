import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
    {
        path: '', redirectTo: 'raiz', pathMatch: 'full'
    },
    {
        path: 'home', loadChildren: () => import('./Pages/Core/tabs/tabs.module').then(m => m.TabsPageModule)
    },
    {
        path: 'raiz', loadChildren: () => import('./Pages/Core/raiz/raiz.module').then(m => m.RaizPageModule)
    },
    {
        path: 'modal-solicitar-reservado',
        loadChildren: () => import('./Pages/Reservados/modal-solicitar-reservado/modal-solicitar-reservado.module')
            .then(m => m.ModalSolicitarReservadoPageModule)
    },
    {
        path: 'modal-evento-mesas',
        loadChildren: () => import('./Pages/Reservados/modal-evento-mesas/modal-evento-mesas.module')
            .then(m => m.ModalEventoMesasPageModule)
    },
    {
        path: 'modal-editar-solicitud-reservado',
        loadChildren: () => import('./Pages/Reservados/modal-editar-solicitud-reservado/modal-editar-solicitud-reservado.module')
            .then(m => m.ModalEditarSolicitudReservadoPageModule)
    },
    {
        path: 'modal-chat-solicitud-reservado',
        loadChildren: () => import('./Pages/Reservados/modal-chat-solicitud-reservado/modal-chat-solicitud-reservado.module')
            .then(m => m.ModalChatSolicitudReservadoPageModule)
    },
    {
        path: 'historico', loadChildren: () => import('./Pages/Configuracion/historico/historico.module').then(m => m.HistoricoPageModule)
    },
    {
        path: 'local', loadChildren: () => import('./Pages/Configuracion/local/local.module').then(m => m.LocalPageModule)
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
