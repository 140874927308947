import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'eventos'
})
export class EventosPipe implements PipeTransform {

    transform(items: any[], filter: any[]) {

        if (filter['id_local'] && filter['id_local'] > 0) {

            items = items.filter(function(item) {
                return item.id_local === filter['id_local'];
            });
        }

        return items;
    }

}
