import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'orden'
})
export class OrdenPipe implements PipeTransform {

    transform(items: any[], filter: string): any {

        if (items) {

            console.log(filter);
            return items.sort((a, b) => b[filter] - a[filter]);

        } else {

            return items;

        }
    }

}
